import { reject } from 'lodash'

export default {
	namespaced: true,
	state: {
		menu: [
			{
				id: 0,
				text: 'home',
				url: '/'
			},
			{
				id: 1,
				text: 'projects',
				url: '/projekty'
			},
			{
				id: 2,
				text: 'study',
				url: '/nauka'
			},
			{
				id: 3,
				text: 'about',
				url: '/o-nas'
			},
			{
				id: 4,
				text: 'contact',
				url: '/kontakt'
			}
		],
		project: null
	},
	getters: {
		menu: (state) => reject(state.menu, { id: 0 }),
		mobileMenu: (state) => state.menu,
		project: (state) => state.project
	},
	mutations: {
		setProject: (state, project) => {
			state.project = project
		}
	},
	actions: {
		setProject({ commit }, project) {
			commit('setProject', project)
		}
	}
}
