import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pl from './pl.json'
import { functions } from './functions'

Vue.use(VueI18n)

const messages = {
	pl: pl
}
export const i18n = new VueI18n({
	locale: 'pl',
	fallbackLocale: 'pl',
	messages,
	postTranslation: (str) => functions.removeOrphans(str)
})
