import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import EasySlider from 'vue-easy-slider'
import { functions } from './plugins/functions'

import { i18n } from '@/plugins/i18n'

Vue.use(EasySlider)

Vue.config.productionTip = false
Vue.prototype.$imgUrl = functions.imgUrl
Vue.prototype.$firstBold = functions.firstBold

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App)
}).$mount('#app')
