import { WORKSHOP, LIBRARY, DOCTORATE } from '@/constants'

export default {
	namespaced: true,
	state: {
		data: [
			{
				id: 0,
				type: WORKSHOP,
				labels: ['school', 'name', 'department', 'course', 'leader', 'location'],
				texts: [0, 1, 2, 3],
				assets: [
					{
						id: '01',
						text: 'Seweryn Trzyna, Kamil Ziółkowski',
						cover: '01_Seweryn_Trzyna_Kamil_Ziolkowski_ikona.jpg',
						assets: [
							'01_trzyna_ziolkowski.jpg',
							'02_trzyna_ziolkowski.jpg',
							'03_trzyna_ziolkowski.jpg'
						]
					},
					{
						id: '02',
						text: 'Dagna Dembiecka',
						cover: '02_Dagna_Dembiecka_ikona.jpg',
						assets: ['Dagna_Dembiecka_1.jpg', 'Dagna_Dembiecka_2.jpg']
					},
					{
						id: '03',
						text: 'Miłosz Andryszewski',
						cover: '03_Milosz_Andryszewski_ikona.jpg',
						assets: ['Plansze_morena_2021.jpg', 'Plansze_morena_2021_2.jpg']
					},
					{
						id: '04',
						text: 'Katarzyna Krawczyk, Zuzanna Kłos',
						cover: '04_Katarzyna_Krawczyk_Zuzanna_Klos_ikona.jpg',
						assets: [
							'1_Katarzyna_Krawczyk_Zuzanna_Klos.jpg',
							'2_Katarzyna_Krawczyk_Zuzanna_Klos.jpg',
							'3_Katarzyna_Krawczyk_Zuzanna_Klos.jpg',
							'4_Katarzyna_Krawczyk_Zuzanna_Klos.jpg',
							'5_Katarzyna_Krawczyk_Zuzanna_Klos.jpg'
						]
					},
					{
						id: '05',
						text: 'Joanna Lipnicka, Eryk Szczepański',
						cover: '05_Joanna_Lipnicka_Eryk_Szczepanski_ikona.jpg',
						assets: [
							'01_Joanna_Lipnicka_Eryk_Szczepanski.jpg',
							'02_Joanna_Lipnicka_Eryk_Szczepanski.jpg'
						]
					},
					{
						id: '06',
						text: 'Iga Stanisławska, Michał Orlikowski',
						cover: '06_Iga_Stanislawska_Michal_Orlikowski_ikona.jpg',
						assets: [
							'1_Iga_Stanislawska_Michal_Orlikowski.jpg',
							'2_Iga_Stanislawska_Michal_Orlikowski.jpg'
						]
					},
					{
						id: '07',
						text: 'Adrianna Nowakowska, Sebastian Jędrzejewski',
						cover: '07_Adrianna_Nowakowska_Sebastian_Jedrzejewski_ikona.jpg',
						assets: [
							'07_Adrianna_Nowakowska_Sebastian_Jedrzejewski_1.jpg',
							'07_Adrianna_Nowakowska_Sebastian_Jedrzejewski_2.jpg'
						]
					},
					{
						id: '08',
						text: 'Aleksandra Biernacka',
						cover: '08_Aleksandra_Biernacka_ikona.jpg',
						assets: [
							'08_Aleksandra_Biernacka_1.jpg',
							'08_Aleksandra_Biernacka_2.jpg',
							'08_Aleksandra_Biernacka_3.jpg'
						]
					},
					{
						id: '09',
						text: 'Kornelia Traut, Sebastian Jędrzejewski',
						cover: 'Kornelia_Traut_Sebastian_Jedrzejewski_ikona.jpg',
						assets: [
							'Kornelia_Traut_Sebastian Jedrzejewski_1.png',
							'Kornelia_Traut_Sebastian Jedrzejewski_2.png',
							'Kornelia_Traut_Sebastian Jedrzejewski_3.png',
							'Kornelia_Traut_Sebastian Jedrzejewski_4.png',
							'Kornelia_Traut_Sebastian Jedrzejewski_5.png'
						]
					},
					{
						id: '10',
						text: 'Marta Staniszewska, Martyna Domeradzka',
						cover: 'Marta_Staniszewska_Martyna_Domaradzka_ikona.png',
						assets: [
							'Marta_Staniszewska_Martyna_Domaradzka_1.png',
							'Marta_Staniszewska_Martyna_Domaradzka_2.png',
							'Marta_Staniszewska_Martyna_Domaradzka_3.png'
						]
					},
					{
						id: '11',
						text: 'Jędrzej Jedwabny',
						cover: 'Jędrzej_Jedwabny_ikona.png',
						assets: [
							'Jędrzej Jedwabny_1.png',
							'Jędrzej Jedwabny_2.png',
							'Jędrzej Jedwabny_3.png',
							'Jędrzej Jedwabny_4.png',
						]
					},
					{
						id: '12',
						text: 'Malwina Bleja, Klaudia Skibińska',
						cover: 'Malwina_Bleja_Klaudia_Skibińska_ikona.png',
						assets: [
							'Malwina Bleja_Klaudia Skibińska_1.png',
							'Malwina Bleja_Klaudia Skibińska_2.png',
							'Malwina Bleja_Klaudia Skibińska_3.png',
							'Malwina Bleja_Klaudia Skibińska_4.png'
						]
					},
					{
						id: '13',
						text: 'Sebastian Jędrzejewski',
						cover: 'sebastian_jędrzejewski_ikona.jpg',
						assets: [
							'Sebastian Jędrzejewski_1.png',
							'Sebastian Jędrzejewski_2.png',
							'Sebastian Jędrzejewski_3.png',
							'Sebastian Jędrzejewski_4.png',
							'Sebastian Jędrzejewski_5.png',
							'Sebastian Jędrzejewski_6.png',
							'Sebastian Jędrzejewski_7.png',
							'Sebastian Jędrzejewski_8.png',
						]
					},
					{
						id: '14',
						text: 'Klara Kondracka',
						cover: 'Klara_Kondracka_ikona.png',
						assets: [
							'Klara Kondracka_1.png',
							'Klara Kondracka_2.png',
							'Klara Kondracka_3.png',
							'Klara Kondracka_4.png',
							'Klara Kondracka_5.png',
						]
					}
				]
			},
			{
				id: 1,
				type: LIBRARY,
				labels: ['school', 'name', 'department', 'course', 'leader', 'location'],
				texts: [0, 1, 2, 3, 4, 5],
				assets: [
					{
						id: 'lib',
						cover: '1.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '2.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '3.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '4.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '5.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '6.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '7.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '8.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '9.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '10.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '11.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '12.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '13.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '14.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '15.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '16.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '17.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '18.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '19.jpg',
						group: true
					},
					{
						id: 'lib',
						cover: '20.jpg',
						group: true
					}
				]
			},
			{
				id: 2,
				type: DOCTORATE,
				labels: [
					'school',
					'department',
					'work-title',
					'promoter',
					'reviewer1',
					'reviewer2',
					'year'
				],
				texts: [0, 1, 2, { type: 'link', url: 3 }],
				assets: [
					{
						id: 'doc',
						cover: 'EQ-edit.gif',
						group: true,
						link: 'https://www.youtube.com/watch?v=vt1FROPLFr4&ab_channel=JG'
					}
				]
			}
		]
	},
	getters: {
		data: (state) => state.data
	},
	mutations: {},
	actions: {}
}
