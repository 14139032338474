<template>
	<footer class="footer__wrap">
		<div class="footer__image">
			<img :src="$imgUrl('EQULIBRE.svg')" />
			<img v-if="isCertVisible" :src="$imgUrl('contact/certyfikat_PHI_1.jpg')" />
		</div>
		<div class="footer__copy">
			<p>{{ $t('footer.p1') }}</p>
			<p>
				{{ $t('footer.p2') }}
				<a :href="$t('external.cud')">
					{{ $t('footer.credits') }}
				</a>
			</p>
		</div>
	</footer>
</template>
<script>
export default {
	computed: {
		isCertVisible: ({ $route }) => $route.name !== 'contact'
	}
}
</script>
