export const WORKSHOP = 'workshop'
export const LIBRARY = 'library'
export const DOCTORATE = 'doctorate'

export const ARCHITECTURE = 'architecture'
export const INTERIOR = 'interior'
export const EXHIBITIONS = 'exhibitions'
export const FURNITURE = 'furniture'

export const ARCHITECTURE_SLUG = 'architektura'
export const INTERIOR_SLUG = 'wnetrza'
export const EXHIBITIONS_SLUG = 'wystawy'
export const FURNITURE_SLUG = 'meble'

export const IDEA = 'idea'
export const PROJECTION = 'projection'
export const VISUALISATIONS = 'visualisations'
export const PHOTOS = 'photos'

export const SINGLE_FAMILY_HOMES = 'single-family-homes'
export const PUBLIC_BUILDINGS = 'public-buildings'

export const PRIVATE_INTERIORS = 'private-interiors'
export const PUBLIC_INTERIORS = 'public-interiors'
export const MUSEUM_EXHIBITIONS = 'museum-exhibitions'
