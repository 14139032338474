import Home from '../pages/home'
import Projects from '../pages/projects'
import Project from '../pages/project'
import Study from '../pages/study'
import About from '../pages/about'
import Contact from '../pages/contact'

import PageNotFound from '../pages/404'
import RouterHelper from '../helpers/router-helper'

export default [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/projekty',
		component: RouterHelper,
		children: [
			{
				path: '',
				name: 'projects',
				component: Projects
			},
			{
				path: ':project/:name',
				component: Project
			}
		]
	},
	{
		path: '/nauka',
		name: 'study',
		component: Study
	},
	{
		path: '/o-nas',
		name: 'about',
		component: About
	},
	{
		path: '/kontakt',
		name: 'contact',
		component: Contact
	},
	{
		path: '*',
		name: 'PageNotFound',
		component: PageNotFound
	}
]
