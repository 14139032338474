<template>
	<article class="study">
		<div class="study__projects">
			<transition name="fade" mode="out-in">
				<h2 v-if="project" class="study__heading" :key="project.type">
					{{ $t(`study.${project.type}.title`) }}
				</h2>
			</transition>
			<transition name="fade" mode="out-in">
				<div class="study__projects-scroll" v-if="project" :key="project.type">
					<div class="study__projects-wrap">
						<div
							v-for="(asset, idx) in project.assets"
							:class="['cover', { 'cover-video': asset.link }]"
							:key="asset.cover"
							:style="{
								'background-image': `url(${$imgUrl(
									`study/${project.type}/${asset.id}/${asset.cover}`
								)})`
							}"
							@click="onClick(asset, idx)"
							@mouseover="imageHover = asset"
							@mouseleave="imageHover = null"
						/>
					</div>
				</div>
			</transition>
		</div>
		<filter-buttons
			class="study__filters"
			translateKey="study"
			:project="project"
			:projects="projects"
			@set-project="setProject"
		/>
		<div class="study__info">
			<transition name="fade" mode="out-in">
				<div v-if="!project || imageHover" class="study__info-cover">
					<div
						class="image"
						:style="{
							'background-image': `url(${$imgUrl(image)})`
						}"
					/>
					<p v-if="author">{{ author }}</p>
				</div>
				<study-text v-else :key="id" :project="project" />
			</transition>
		</div>
		<div :class="['study__info-mobile', { active: textVisible }]">
			<transition name="fade" mode="out-in">
				<img
					v-if="!project"
					:src="$imgUrl(`study/IMG-nature.jpg`)"
					alt="image"
					:key="image"
				/>
				<study-text v-else-if="textVisible" :project="project" />
			</transition>
		</div>

		<div class="study__switch" v-if="project" @click="textVisible = !textVisible">
			<transition name="fade" mode="out-in">
				<img :src="$imgUrl(switchImage)" alt="switch" :key="textVisible" />
			</transition>
		</div>

		<Tinybox
			v-model="tinyIndex"
			:images="tinyImages"
			no-thumbs
			@close="index = null"
		/>
	</article>
</template>

<script>
import Tinybox from 'vue-tinybox'
import { get, map, isNil, filter } from 'lodash'
import FilterButtons from '@/components/filter-buttons'
import StudyText from '@/components/study-text'
import { YouTubePopUp } from '@/plugins/YouTubePopUp'

export default {
	components: { Tinybox, FilterButtons, StudyText },
	data: () => ({
		project: null,
		index: null,
		imageHover: null,
		textVisible: false,
		tinyImages: [],
		tinyIndex: null
	}),
	computed: {
		id: ({ project }) => get(project, 'id', null),
		projects: ({ $store }) => $store.getters['study/data'],
		image: ({ project, imageHover }) =>
			project && imageHover
				? `study/${project.type}/${imageHover.id}/big/${imageHover.cover}`
				: `study/IMG-nature.jpg`,
		author: ({ imageHover }) => get(imageHover, 'text') || null,
		switchImage: ({ textVisible }) => (textVisible ? 'TXT-close.png' : 'TXT.svg')
	},
	watch: {
		index(val) {
			if (!isNil(val)) {
				this.setTinyImages()
			} else {
				this.tinyImages = []
				this.tinyIndex = null
			}
		}
	},
	methods: {
		setProject(project) {
			this.project = project
			this.textVisible = false
		},
		setTinyImages() {
			if (get(this.project, `assets[${this.index}].group`)) {
				const array = filter(get(this.project, `assets`), {
					id: get(this.project, `assets[${this.index}].id`)
				})

				this.tinyImages = map(array, (asset) => ({
					src: this.$imgUrl(
						`study/${this.project.type}/${asset.id}/big/${asset.cover}`
					),
					caption: get(asset, `text`) || null
				}))

				this.tinyIndex = this.index
			} else {
				this.tinyImages = map(
					get(this.project, `assets[${this.index}]assets`, []),
					(asset) => ({
						src: this.$imgUrl(
							`study/${this.project.type}/${
								this.project.assets[this.index].id
							}/big/${asset}`
						),
						caption: get(this.project, `assets[${this.index}]text`) || null
					})
				)

				this.tinyIndex = 0
			}
		},
		onClick(asset, idx) {
			if (asset.link) {
				this.playVideo(asset.link)
			} else {
				this.index = idx
			}
		},
		playVideo(video) {
			YouTubePopUp(video)
		}
	}
}
</script>
