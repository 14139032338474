<template>
	<div id="app">
		<app-header />
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>
		<app-footer v-if="footerVisible" />
	</div>
</template>

<script>
import AppHeader from '@/components/app-header'
import AppFooter from '@/components/app-footer'
import { includes } from 'lodash'

export default {
	name: 'App',
	components: {
		AppHeader,
		AppFooter
	},
	data: () => ({
		footerEnabled: ['/', '/kontakt']
	}),
	computed: {
		footerVisible: ({ $route, footerEnabled }) =>
			includes(footerEnabled, $route.path)
	}
}
</script>
<style src="./assets/output.css"></style>
